<template>
  <div>
    <v-card>
      <v-toolbar dense dark color="primary">
        <v-toolbar-title><h4 class="font-weight-light">14th MONTH PAYSLIP</h4>
        </v-toolbar-title>
      </v-toolbar>
      <v-row dense class="mt-3">
        <v-col md="3" cols="12">
          <v-select
            v-model="year"
            class="mx-2"
            :items="year_items"
            item-value="year"
            item-text="year"
            label="Year Of"
            required
            :rules="rules.combobox_rule"
            dense
            @change="selected_month"
            outlined
          ></v-select>
        </v-col>
        <v-col md="3" cols="12">
          <v-select
            outlined
            class="mx-2"
            v-model="released_no"
            dense
            label="Released #"
            :items="['1st Semi-Annual','2nd Semi-Annual']"
            :rules="rules.combobox_rule"
            @change="selected_month"
          ></v-select>
        </v-col>
        <v-col md="12" cols="12" v-show="alert">
          <v-alert color="warning" text class="mb-0">
            <div class="d-flex align-start">
              <v-icon color="warning">
                {{ icons.mdiAlertOutline }}
              </v-icon>
              <div class="ms-3">
                <p class="text-base font-weight-medium mb-1">
                  {{ alert_message }}
                </p>
              </div>
            </div>
          </v-alert>
        </v-col>

        <v-col md="12" cols="12">
          <v-data-table dense :headers="headers" :items="payroll_data" :search="search">
            <template v-slot:top>
              <v-toolbar flat>
                <v-toolbar-title>Payroll Data</v-toolbar-title>
                <v-divider class="mx-4" inset vertical></v-divider>

                <v-text-field
                  class="mx-2"
                  :append-icon="icons.mdiAccountSearch"
                  label="Search"
                  single-line
                  hide-details
                  v-model="search"
                ></v-text-field>
              </v-toolbar>
            </template>
            <template v-slot:item="{ item }">
              <tr>
                <td>
                  {{
                  payroll_data
                  .map(function (x) {
                  return x.id
                  })
                  .indexOf(item.id) + 1
                  }}
                </td>
                <td>
                  <v-icon
                    class="mr-2"
                    color="info"
                    @click="save_payment(payroll_data
                .map(function (x) {
                return x.id
                })
                .indexOf(item.id))"
                  >
                    {{icons.mdiPrinter}}
                  </v-icon>
                </td>
                <td>
                  {{ item.employee.last_name + ',' + item.employee.first_name + ' ' +
                  item.employee.middle_name }}
                </td>
                <td>
                  {{ item.status }}
                </td>
                <td>
                  {{ formatPrice(item.total_amount) }}
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-col>
        <!--        <v-col cols="12" md="3" v-if="search === '' && payroll_data.length > 0">-->
        <!--          <v-btn class="w-full" color="primary" @click="save_payment()" v-if="!saving_data"> Print-->
        <!--          </v-btn>-->
        <!--          <v-progress-circular :size="50" :width="5" color="primary" indeterminate-->
        <!--                               v-else></v-progress-circular>-->
        <!--        </v-col>-->
      </v-row>
    </v-card>
    <snack-bar-dialog :snackbar_flag="this.snackbar" :color="this.snackbar_color"
                      :snackbar_text="this.snackbar_text"/>
  </div>
</template>

<script>
  import {
    mdiAccountOutline,
    mdiAccountCashOutline,
    mdiInformationOutline,
    mdiPrinter,
    mdiAccountSearch
  } from '@mdi/js'
  import moment from 'moment'
  import {mapActions, mapGetters} from 'vuex'
  import snackBarDialog from '@/components/dialogs/notifications_dialog/Snackbar'

  const initialState = () => {
    return {
      alert_message: '',
      alert: false,

      duration: '',
      year: '',
      released_no: '',
      year_items: [],
      saving_data: false,

      payroll_data: [],
      search: '',
      headers: [
        {text: 'No.', value: 'id', sortable: false},
        {text: 'Print/Download', value: 'id', sortable: false},
        {text: 'Name', value: 'employee.last_name', sortable: false},
        {text: 'Status', value: 'bank_code_name', sortable: false},
        {text: '14th Month Pay', value: 'bank_code_name', sortable: false},
      ],
    }
  }
  export default {
    components: {
      snackBarDialog,
    },
    setup() {
      return {
        icons: {
          mdiAccountOutline,
          mdiAccountCashOutline,
          mdiInformationOutline,
          mdiAccountSearch,
          mdiPrinter,
        },
        is_deleting: false,
      }
    },
    data() {
      return initialState()
    },
    mounted() {
      this.initialize_data()
    },
    computed: {
      ...mapGetters('form_rules', ['rules']),
      ...mapGetters('authentication', [
        'employee_id',
        'month_of',
        'address',
        'contact',
        'name',
        'department',
        'position',
      ]),
      ...mapGetters('system_data', [
        'snackbar',
        'snackbar_color',
        'snackbar_text',
        'company_logo',
        'damayan',
        'chapel',
        'factory',
        'coop',
        'printing',
        'accounting',
        'admin',
        'amd',
        'audit',
        'book',
        'creative',
        'ecut',
        'hr',
        'it',
        'prot',
        'trea',

        'bod_hilario_signature',
        'intan_signature',
        'maica_signature',
      ]),
    },
    methods: {
      ...mapActions('system_data', ['change_snackbar']),
      ...mapActions('salaries_and_wages', ['initialize_salaries_employee']),
      ...mapActions('fourteen_month_pay', ['fourteen_payslip_data']),
      initialize_data() {
        this.initialize_salaries_employee()
          .then(response => {
            this.year_items = response.data[0].month_of
          })
          .catch(error => {
            console.log(error)
          })
      },
      date_format(value) {
        return moment(value)
      },
      formatPrice(value) {
        let val = (value / 1).toFixed(2).replace(',', '.')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      },
      selected_month() {
        const data = new FormData()
        data.append('year', this.year)
        data.append('employee_id', this.employee_id)
        data.append('is_not_admin', this.position != 'ADMIN' && this.position != 'HUMAN RESOURCE' ? 1 : 0)
        data.append('released_no', this.released_no === '1st Semi-Annual' ? 1 : 2);
        this.fourteen_payslip_data(data)
          .then(response => {
            this.payroll_data = response.data
          })
          .catch(error => {
            console.log(error)
          })
      },
      async save_payment(index) {
        this.saving_data = true
        var imgData = this.company_logo

        var payments_array = []
        var widths = [500]
        var addr = this.address
        var cont = this.contact
        var pos = this.position

        var ad = this.admin
        var tree = this.trea
        var accc = this.accounting
        var ammdd = this.amd
        var proo = this.prot
        var hrrr = this.hr
        var boook = this.book

        var damayan_ = this.damayan
        var chapel_ = this.chapel
        var factory_ = this.factory
        var coop_ = this.coop
        var printing_ = this.printing


        var bod_hilario_signature_ = this.bod_hilario_signature
        var intan_signature_ = this.intan_signature
        var maica_signature_ = this.maica_signature

        var title = '14th MONTH PAY'

        var salary_cover =
          this.year
        var item = this.payroll_data[index]
        if (item.is_bunos_only === 1) {
          title = 'CHRISTMAS BUNOS'
        }
        payments_array.push([
          {
            columns: [
              {image: imgData, width: 40, height: 34, style: 'logo'},
              {
                stack: [
                  'GOODLIFE GROUP OF COMPANIES',
                  {text: addr, style: 'subheader2'},
                  {
                    text: cont,
                    style: 'subheader2',
                  },
                  {
                    text: 'Printed as of: ' + moment().format('MMMM D, YYYY'),
                    style: 'printed_label',
                  },
                ],
                style: 'header',
              },
              {
                image: tree,
                width: 40,
                height: 34,
                style: 'logo',
              },
            ],
          },
          {
            text: title + ' SLIP',
            style: {
              fontSize: 15,
              alignment: 'center',
              bold: true,
            },
          },
          {text: ' '},
          {
            columns: [
              {
                style: 'main_info',
                text: [
                  'EMPLOYEE NAME: ',
                  {
                    text: item.employee.last_name + ',' + item.employee.first_name + ' ' + item.employee.middle_name,
                    fontSize: 12,
                    bold: true,
                  },
                ],
              },
              {
                style: 'main_info',
                text: [
                  'YEAR OF: ',
                  {
                    text: salary_cover,
                    fontSize: 12,
                    bold: true,
                  },
                ],
              },
            ],
          },

          {text: ' '},
          {text: ' '},
          {
            columns: [
              {text: ' '},
              {
                text: (
                  (parseFloat(item.total_amount)) /
                  1
                )
                  .toFixed(2)
                  .replace(',', '.')
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                fontSize: 40,
                color: 'blue',
                bold: true,
              },
              {text: ' '},
            ],
          },
          {
            stack: [
              {
                columns: [{text: ' '}],
              },
              {
                columns: [
                  {
                    text: 'PREPARED BY:',
                    style: 'main_data_2',
                    alignment: 'center',
                  },

                  {
                    text: 'PROCESS BY:',
                    style: 'main_data_2',
                    alignment: 'center',
                  },
                ],
              },
              {
                columns: [
                  {
                    text: ' ',
                  },

                  {
                    text: ' ',
                  }
                ],
              },
              {
                columns: [
                  {
                    image: maica_signature_, width: 100, height: 50,
                    margin: [85, 0, 0, 0]
                  },
                  {
                    image: intan_signature_, width: 150, height: 50,
                    margin: [220, 0, 0, 0]
                  },

                ],
              },
              {
                columns: [
                  {
                    text: 'VALENTIN, PAULYN',
                    style: 'main_data_2',
                    alignment: 'center',
                  },
                  {
                    text: 'INTAN, ROLDAN C.',
                    style: 'main_data_2',
                    alignment: 'center',
                  },
                ],
              },
              {
                columns: [
                  {
                    text: 'HR DEPARTMENT',
                    style: 'main_data_2',
                    alignment: 'center',
                  },
                  {
                    text: 'FINANCE DEPARTMENT',
                    style: 'main_data_2',
                    alignment: 'center',
                  },
                ],
              },
              {
                columns: [
                  {
                    text: ' ',
                  },
                  {
                    text: ' ',
                  },
                ],
              },
              {
                columns: [
                  {
                    text: ' ',
                  },
                  {
                    text: ' ',
                  },
                ],
              },
              {
                columns: [
                  {
                    style: 'main_data_2',
                    text: 'APPROVED BY:',
                    alignment: 'center',
                  },
                ],
              },
              {
                columns: [
                  {
                    image: bod_hilario_signature_, width: 120, height: 50,
                    margin: [200, 0, 0, 0]
                  },
                ],
              },
              {
                columns: [
                  {
                    text: 'ALBERT M. HILARIO',
                    style: 'main_data_2',
                    alignment: 'center',
                  },
                ],
              },
              {
                columns: [
                  {
                    text: 'EVP HEAD FINANCE',
                    style: 'main_data_2',
                    alignment: 'center',
                  },
                ],
              },
            ],
          },
        ])
        if ((index + 1) % 4 === 0) {
          payments_array.push([{text: ' '}, {text: ' '}, {text: ' '}, {text: ' '}, {text: ' '}])
        }
        // if (!Object.is(payment.length - 1, index)) {
        //   payments_array.push([
        //     {
        //       columns: [
        //         {
        //           text: ' ',
        //         },
        //       ],
        //       pageBreak: 'after',
        //     },
        //   ])
        // }
        //call pdfmake
        var pdfMake = require('pdfmake/build/pdfmake.js')
        if (pdfMake.vfs == undefined) {
          var pdfFonts = require('pdfmake/build/vfs_fonts.js')
          pdfMake.vfs = pdfFonts.pdfMake.vfs
        }
        var docDefinition = {
          pageSize: 'LETTER',
          pageOrientation: 'portrait',
          content: payments_array,
          footer: function (currentPage, pageCount) {
            return {
              margin: 10,
              columns: [
                {
                  text: 'Page ' + currentPage.toString() + ' of ' + pageCount,
                  fontSize: 9,
                  alignment: 'left',
                },
                {image: damayan_, width: 34, height: 20, style: 'logo'},
                {image: chapel_, width: 34, height: 20, style: 'logo'},
                {image: factory_, width: 34, height: 20, style: 'logo'},
                {image: coop_, width: 34, height: 20, style: 'logo'},
                {image: printing_, width: 34, height: 20, style: 'logo'},
                {
                  text: '',
                },
              ],
            }
          },
          styles: {
            tableExample: {
              fontSize: 9,
            },
            tableExample2: {
              fontSize: 7,
            },
            header: {
              fontSize: 15,
              bold: true,
              alignment: 'left',
              margin: [6, 6, 0, 5], //[left, top, right, bottom]
            },
            subheader3: {
              fontSize: 11,
            },
            subheader: {
              fontSize: 9,
            },
            subheader2: {
              fontSize: 8,
            },
            logo: {
              alignment: 'center',
              margin: [0, 0, 0, 0], //[left, top, right, bottom]
            },
            printed_label: {
              alignment: 'right',
              fontSize: 9,
              margin: [0, 0, 0, 0], //[left, top, right, bottom]
            },
            main_data: {
              margin: [0, 2, 0, 2],
              fontSize: 9,
              bold: true,
              alignment: 'left',
            },
            main_data_2: {
              margin: [0, 2, 0, 2],
              fontSize: 8,
              bold: true,
              alignment: 'left',
            },
            main_info: {
              margin: [0, 2, 0, 2],
              fontSize: 10,
              alignment: 'left',
            },
            main_info2: {
              margin: [0, 2, 0, 2],
              fontSize: 30,
              alignment: 'left',
            },
          },
        }
        pdfMake.createPdf(docDefinition).open()
        this.saving_data = false
      },
    },
  }
</script>
